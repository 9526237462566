import { proxy } from "valtio";

const defaultData = [
    {
        "_id": "64bfcb87148ee442ca3b8022",
        "name": "Fillet of beef with horseradish cream",
        "ingredients": [
            "4 fillets of beef, about 6 ounces (170g) each, at room temperature",
            "Salt and freshly ground black pepper to taste",
            "2 tablespoons olive oil\", \"1 cup heavy cream",
            "3 tablespoons prepared horseradish (adjust to taste)",
            "1 tablespoon Dijon mustard",
            "1 tablespoon fresh lemon juice",
            "2 tablespoons chopped fresh chives (optional, for garnish)"
        ],
        "instructions": "Preheat your oven to 400°F (200°C). Take the fillets of beef out of the refrigerator and let them come to room temperature for about 30 minutes. This ensures even cooking. Pat dry the fillets with paper towels to remove any excess moisture. Season each fillet generously with salt and freshly ground black pepper on all sides",
        "imageUrl": "https://simply-delicious-food.com/wp-content/uploads/2018/11/rosemary-crusted-fillet-of-beef-with-horseradish-cream-2.jpg",
        "cookingTime": 45,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64c648cb0c62c35ab3347c93",
        "name": "Tomhok Fruit Salad",
        "ingredients": [
            "1 tomhok",
            "2 bananas",
            "1 cup blueberries"
        ],
        "instructions": "1. Peel and slice the tomhok into small chunks.\n2. Peel and slice the bananas into thin slices.\n3. Rinse the blueberries under cold water.\n4. In a mixing bowl, combine the tomhok chunks, banana slices, and blueberries.\n5. Gently toss the fruits together until well mixed.\n6. Serve the fruit salad chilled. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-W0QGMQpo8SIqNfxr8jh5PqJt.png?st=2023-07-30T10%3A25%3A49Z&se=2023-07-30T12%3A25%3A49Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-07-30T08%3A03%3A27Z&ske=2023-07-31T08%3A03%3A27Z&sks=b&skv=2021-08-06&sig=ha%2BBOazWh0OfhA1uD8sVITqIkT9ikaKwNGSp94ZvbDg%3D",
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64c6d1803a3883398c1035a9",
        "name": "Blueberry Asparagus Salad",
        "ingredients": [
            "1 bunch of asparagus",
            "1 cup of blueberries",
            "Assorted salad vegetables (optional)",
            "2 tablespoons of olive oil",
            "1 tablespoon of lemon juice",
            "1 teaspoon of honey",
            "Salt and pepper to taste"
        ],
        "instructions": "1. Rinse and trim the asparagus spears.\n2. Bring a pot of water to boil and blanch the asparagus for 2-3 minutes until crisp-tender. Drain and immediately transfer to an ice bath to stop the cooking process.\n3. In a large salad bowl, combine the blanched asparagus, blueberries, and any additional desired salad vegetables.\n4. In a small mixing bowl, whisk together olive oil, lemon juice, honey, salt, and pepper to make a dressing.\n5. Drizzle the dressing over the salad ingredients and toss gently to combine. Serve chilled.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-DuDkTg9WCldIKIYcVJD7n0MJ.png?st=2023-07-30T20%3A09%3A04Z&se=2023-07-30T22%3A09%3A04Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-07-30T01%3A05%3A42Z&ske=2023-07-31T01%3A05%3A42Z&sks=b&skv=2021-08-06&sig=M4flRy7WOF1YfpxxSQbdDFsBTQwhNlHYzGV6b5r%2B1z8%3D",
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64c7a2bd3a3883398c103613",
        "name": "Cheesy Broccoli Bake",
        "ingredients": [
            "2 cups of broccoli florets",
            "2 cups of grated cheese"
        ],
        "instructions": "1. Preheat the oven to 375°F (190°C).\n2. Steam the broccoli florets until they are tender, but still retain their bright green color.\n3. In a medium-sized baking dish, layer the steamed broccoli evenly.\n4. Sprinkle grated cheese generously over the top of the broccoli.\n5. Bake in the preheated oven for about 15-20 minutes until the cheese is melted and bubbly.\n6. Remove from the oven and let it cool for a few minutes before serving.\n7. Enjoy your delicious and cheesy broccoli bake!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-2hh6yZy6iCGCyR6oLyOhM95g.png?st=2023-07-31T11%3A01%3A26Z&se=2023-07-31T13%3A01%3A26Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-07-30T18%3A26%3A02Z&ske=2023-07-31T18%3A26%3A02Z&sks=b&skv=2021-08-06&sig=gCTi5u624/KxW8hoUHW6%2B2hCymGCqsbAYVvfvvL%2BpJM%3D",
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64c7a3a92673028bc137f06c",
        "name": "Tomahawk Tomato Steak",
        "ingredients": [
            "1 Tomahawk steak (about 2 pounds)",
            "2 tomatoes",
            "Salt",
            "Black pepper",
            "Steak seasoning"
        ],
        "instructions": "1. Preheat the grill to high heat. \n2. Rub the tomahawk steak with salt, pepper, and your favorite steak seasoning. \n3. Slice the tomato into thick slices. \n4. Place the seasoned tomahawk steak and tomato slices on the grill. \n5. Grill the steak for 15 minutes on each side for medium-rare, or adjust cooking time to your desired level of doneness. \n6. Grill the tomato slices for 3-4 minutes per side, until slightly charred and softened. \n7. Remove the steak and tomato slices from the grill and let them rest for a few minutes. \n8. Slice the tomahawk steak against the grain into thick, juicy pieces. \n9. Serve the steak with the grilled tomato slices on the side. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-0Iv1ZdToNCSK2HVTglgfTNaT.png?st=2023-07-31T11%3A05%3A10Z&se=2023-07-31T13%3A05%3A10Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-07-30T18%3A30%3A26Z&ske=2023-07-31T18%3A30%3A26Z&sks=b&skv=2021-08-06&sig=Qh3CuRvjsr6EuXdknEPfeQdMQZqltlDRoedLBgEoBT0%3D",
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64c9e96385a25f77f373f07c",
        "name": "Blueberry Apple Asparagus Salad",
        "ingredients": [
            "1 cup blueberries",
            "2 apples, thinly sliced",
            "1 bunch asparagus",
            "2 tablespoons olive oil"
        ],
        "instructions": "1. Wash and trim the asparagus spears. Cut them into bite-sized pieces.\n2. Heat a pan with some olive oil over medium heat.\n3. Add the asparagus pieces to the pan and sauté for 5-7 minutes, until they are tender-crisp. Set aside to cool.\n4. In a large bowl, combine the blueberries, thinly sliced apples, and cooled asparagus.\n5. Toss gently to mix the ingredients well.\n6. Serve immediately or refrigerate for a while to allow the flavors to blend. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-TsMSqb3BH9WtYwSto2SKeIR8.png?st=2023-08-02T04%3A27%3A40Z&se=2023-08-02T06%3A27%3A40Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-01T18%3A08%3A31Z&ske=2023-08-02T18%3A08%3A31Z&sks=b&skv=2021-08-06&sig=ZhCsrzAAJ3DVtEyH/FJC4KNs/d11cDA89TxUfx0VAs0%3D",
        "cookingTime": 20,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64cf7d611c1f586c965a092a",
        "name": "Chicken Tomato Recipe",
        "ingredients": [
            "Chicken pieces",
            "Tomatoes",
            "Oil",
            "Salt",
            "Pepper",
            "Herbs (optional)"
        ],
        "instructions": "1. Heat some oil in a large pan over medium heat.\n2. Add the chicken pieces to the pan and cook until they are golden brown.\n3. Remove the chicken from the pan and set aside.\n4. In the same pan, add the tomatoes and cook until they start to soften.\n5. Add the cooked chicken back to the pan and stir well.\n6. Cover the pan and simmer for about 15 minutes, or until the chicken is cooked through.\n7. Serve hot with your favorite side dishes.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-xt3iBU9jTMMajPgq4wIqpsHx.png?st=2023-08-06T10%3A00%3A33Z&se=2023-08-06T12%3A00%3A33Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-06T09%3A14%3A52Z&ske=2023-08-07T09%3A14%3A52Z&sks=b&skv=2021-08-06&sig=eG/mKN72H01VNY/c7qPbed5Uq2NsSSnpy0hEjrAwUVM%3D",
        "cookingTime": 30,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64d004df1fe12d3a51ca31ae",
        "name": "Quick and Easy Pasta Salad",
        "ingredients": [
            "8 ounces pasta",
            "1 cup cherry tomatoes, halved",
            "1 small cucumber, diced",
            "1/4 cup red onion, thinly sliced",
            "1/4 cup black olives, sliced",
            "1/2 cup feta cheese, crumbled",
            "3 tablespoons olive oil",
            "2 tablespoons white vinegar",
            "1 tablespoon Dijon mustard",
            "Salt and pepper to taste"
        ],
        "instructions": "1. Cook pasta according to package instructions. Drain and set aside.\n2. In a small mixing bowl, whisk together olive oil, vinegar, mustard, salt, and pepper to make the dressing.\n3. In a large mixing bowl, combine cooked pasta, cherry tomatoes, cucumber, red onion, olives, and feta cheese.\n4. Pour the dressing over the pasta salad and toss gently to coat.\n5. Refrigerate for at least 1 hour to allow flavors to mingle.\n6. Serve chilled and enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-KWNhJS8QrenU9BtkmaojOgpa.png?st=2023-08-06T19%3A38%3A18Z&se=2023-08-06T21%3A38%3A18Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-06T01%3A09%3A18Z&ske=2023-08-07T01%3A09%3A18Z&sks=b&skv=2021-08-06&sig=Bvhr44jSBAgm8sNsbOoHPwfLfbI9Z%2Bkt/HZGaRWGMWg%3D",
        "cookingTime": 20,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64d005121fe12d3a51ca31bd",
        "name": "Spicy Avocado Toast",
        "ingredients": [
            "2 slices of bread",
            "1 ripe avocado",
            "Salt and pepper to taste",
            "2 tablespoons olive oil",
            "2 cloves of garlic, minced",
            "1/2 teaspoon red pepper flakes",
            "Chopped cilantro for garnish"
        ],
        "instructions": "1. Toast the bread slices until golden brown. \n2. In a bowl, mash the avocado with a fork and season with salt and pepper. \n3. In a small saucepan, heat olive oil over medium heat. Add minced garlic and red pepper flakes and sauté for 1-2 minutes. \n4. Spread the mashed avocado on the toasted bread slices. \n5. Drizzle the spicy garlic oil over the avocado. \n6. Garnish with chopped cilantro. \n7. Serve immediately and enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-CmKRGIqUIyUmaIpWY7YuPIkT.png?st=2023-08-06T19%3A39%3A28Z&se=2023-08-06T21%3A39%3A28Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-06T00%3A38%3A09Z&ske=2023-08-07T00%3A38%3A09Z&sks=b&skv=2021-08-06&sig=o2wQyjpd%2BD5mr4Yc2bUIXX42SNYv4CadhVxogkx5m6E%3D",
        "cookingTime": 10,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64d006f5cc1e07dc6d2278d5",
        "name": "Grilled Salmon with Lemon and Herbs",
        "ingredients": [
            "4 salmon fillets",
            "Juice of 1 lemon",
            "2 cloves garlic, minced",
            "2 tablespoons chopped fresh parsley",
            "2 tablespoons chopped fresh dill",
            "Salt and pepper, to taste",
            "Fresh lemon slices, for garnish",
            "Fresh herbs, sprigs for garnish"
        ],
        "instructions": "1. Preheat the grill to medium-high heat. \n2. In a small bowl, mix together the juice of one lemon, minced garlic, chopped fresh parsley, chopped fresh dill, salt, and pepper.\n3. Place the salmon fillets on a large piece of foil. \n4. Brush the lemon and herb mixture evenly over the salmon fillets. \n5. Carefully wrap the foil, creating a packet to enclose the salmon completely. \n6. Place the foil packet on the preheated grill and cook for about 15-20 minutes, or until the salmon is cooked through and flakes easily with a fork. \n7. Carefully open the foil packet and transfer the salmon to a serving plate. \n8. Garnish with fresh lemon slices and sprigs of fresh herbs. \n9. Serve hot with a side of steamed vegetables or a fresh salad.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-EXrd8uvn1dbySfLccdqDrlAM.png?st=2023-08-06T19%3A47%3A29Z&se=2023-08-06T21%3A47%3A29Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-06T18%3A14%3A56Z&ske=2023-08-07T18%3A14%3A56Z&sks=b&skv=2021-08-06&sig=r5tChV7JfTINUmp5VFMMhuw/GhBN3dLCqNcbWqjDEsw%3D",
        "cookingTime": 30,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64d12baf86c9d6d65a3778a8",
        "ingredients": [],
        "__v": 0
    },
    {
        "_id": "64d135f08d1ae9ecb91ef040",
        "name": "Tuna Tomato Pasta",
        "description": "A delicious and healthy pasta dish with tuna and tomatoes.",
        "ingredients": [
            "200g pasta",
            "2 tbsp olive oil",
            "3 cloves of garlic, minced",
            "4 large tomatoes, chopped",
            "1 can of tuna, drained",
            "Salt and pepper to taste",
            "1 tsp dried oregano",
            "Fresh basil leaves for garnish"
        ],
        "instructions": "1. Cook the pasta according to package instructions. Drain and set aside. \n2. In a large skillet, heat olive oil over medium heat. \n3. Add minced garlic and sauté for 1-2 minutes until fragrant. \n4. Add chopped tomatoes and cook for 5 minutes until tomatoes start to soften. \n5. Stir in canned tuna and cook for another 3 minutes. \n6. Season with salt, pepper, and dried oregano. \n7. Add cooked pasta to the skillet and toss until fully coated with the tuna and tomato mixture. \n8. Cook for an additional 2-3 minutes until everything is heated through. \n9. Serve hot and garnish with fresh basil leaves if desired.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-xWEucgDPHYvmvOZwrKDXPIiU.png?st=2023-08-07T17%3A20%3A20Z&se=2023-08-07T19%3A20%3A20Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-08-07T18%3A15%3A20Z&ske=2023-08-08T18%3A15%3A20Z&sks=b&skv=2021-08-06&sig=Ulr%2BtCY/kzcE29QMbP6e5OR2yRj9yXRrjOa4LHA9AoE%3D",
        "cookingTime": 30,
        "userOwner": "64b69498feb980e450bfc482",
        "__v": 0
    },
    {
        "_id": "64f586826b7ada2a74cea582",
        "name": "Apple Blueberry Banana Muffins",
        "description": "Delicious and healthy muffins packed with the flavors of apples, blueberries, and bananas.",
        "ingredients": [
            "2 ripe bananas",
            "1 cup diced apples",
            "1/2 cup blueberries",
            "1 1/2 cups all-purpose flour",
            "1/2 cup sugar",
            "1 teaspoon baking powder",
            "1/2 teaspoon baking soda"
        ],
        "instructions": "1. Preheat the oven to 350°F (175°C). \n2. In a mixing bowl, mash two ripe bananas until smooth. \n3. Add 1 cup of diced apples and 1/2 cup of blueberries to the mashed bananas. \n4. In a separate bowl, combine 1 1/2 cups of all-purpose flour, 1/2 cup of sugar, 1 teaspoon of baking powder, and 1/2 teaspoon of baking soda. \n5. Gradually add the dry mixture to the banana mixture, stirring until just combined. \n6. Grease a muffin tin or line it with paper liners. \n7. Spoon the batter evenly into the prepared muffin cups. \n8. Optional: Sprinkle a little sugar on top of each muffin for added sweetness. \n9. Bake in the preheated oven for 20-25 minutes, or until a toothpick inserted into the center of a muffin comes out clean. \n10. Remove from the oven and let the muffins cool for a few minutes before transferring them to a wire rack. \n11. Enjoy the muffins warm or at room temperature!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-V0KCu4mkG1Q83pkllQ7e3eAn.png?st=2023-09-04T06%3A24%3A53Z&se=2023-09-04T08%3A24%3A53Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T06%3A15%3A14Z&ske=2023-09-05T06%3A15%3A14Z&sks=b&skv=2021-08-06&sig=gIZ4dsM9Naf7a611FGoFjefkzE2M2jz8Isz2tgPIjhc%3D",
        "cookingTime": 30,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f5877e9e7ecb0a1c66a6ff",
        "name": "Tomato, Cheese, and Potato Bake",
        "description": "A delicious and hearty baked dish with tomatoes, cheese, and potatoes that is perfect for any meal.",
        "ingredients": [
            "4 medium-sized tomatoes",
            "4 medium-sized potatoes",
            "2 cups grated cheese"
        ],
        "instructions": "1. Preheat the oven to 400°F (200°C).\n2. Slice the potatoes into thin rounds.\n3. Arrange a layer of potato slices in a greased baking dish.\n4. Top the potatoes with a layer of sliced tomatoes.\n5. Sprinkle grated cheese evenly over the tomatoes.\n6. Repeat the layers until all the ingredients are used, ending with a layer of cheese.\n7. Bake in the preheated oven for 30-35 minutes or until the potatoes are tender and the cheese is golden and bubbly.\n8. Remove from the oven and let it cool for a few minutes before serving.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-wPnh8QRQbNA8mzMY6jPZP8uX.png?st=2023-09-04T06%3A29%3A51Z&se=2023-09-04T08%3A29%3A51Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T06%3A16%3A11Z&ske=2023-09-05T06%3A16%3A11Z&sks=b&skv=2021-08-06&sig=SkmV1kVYgb8RudAnnAupH7dk%2BnubesSHhFAtrFwT0AU%3D",
        "cookingTime": 45,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f589d5a922047e37def862",
        "name": "Pork Rib Pasta",
        "description": "A delicious and hearty pasta dish made with tender pork ribs and flavorful sauce.",
        "ingredients": [
            "Pork ribs",
            "Pasta",
            "Salt",
            "Pepper",
            "Favorite spices",
            "Olive oil",
            "Minced garlic",
            "Diced onions",
            "Marinara sauce",
            "Grated Parmesan cheese",
            "Fresh basil leaves"
        ],
        "instructions": "1. Preheat the oven to 350°F (175°C).\n\n2. Season the pork ribs with salt, pepper, and your favorite spices.\n\n3. Place the seasoned ribs on a baking sheet and bake for 30 minutes or until they are cooked through and tender.\n\n4. In the meantime, cook the pasta according to the package instructions until al dente. Drain and set aside.\n\n5. In a large pan, heat olive oil over medium heat. Add minced garlic and sauté until fragrant.\n\n6. Add diced onions and cook until they are soft and translucent.\n\n7. Pour in your choice of marinara sauce and bring it to a simmer.\n\n8. Once the pork ribs are done baking, remove them from the oven and allow them to cool slightly. Then, cut the meat from the bones and shred it into bite-sized pieces.\n\n9. Add the shredded pork ribs to the simmering sauce and cook for another 5 minutes to allow the flavors to blend.\n\n10. Toss the cooked pasta with the sauce and ribs, making sure everything is well coated.\n\n11. Serve hot and garnish with grated Parmesan cheese and fresh basil leaves if desired.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-OBog9jCS2AOfwuwuahmTvD6G.png?st=2023-09-04T06%3A39%3A38Z&se=2023-09-04T08%3A39%3A38Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T06%3A14%3A40Z&ske=2023-09-05T06%3A14%3A40Z&sks=b&skv=2021-08-06&sig=RGzrHjJKqVb%2BYmG9OYDZ5j81HaTmOkoC%2BWeOeliHD4s%3D",
        "cookingTime": 60,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f5b47cebec6d4ab5b19f70",
        "name": "Roasted Pork Ribs with Asparagus and Potatoes",
        "description": "A delicious and flavorful recipe that combines tender pork ribs with roasted asparagus and potatoes.",
        "ingredients": [
            "4 pounds (1.8 kg) pork ribs",
            "1 bunch asparagus spears",
            "4 medium potatoes",
            "Spices for marinating (e.g., garlic powder, paprika, salt, pepper)",
            "Olive oil",
            "Salt and pepper to taste"
        ],
        "instructions": "1. Preheat the oven to 375°F (190°C). \n2. In a large mixing bowl, combine the pork ribs with your choice of spices and marinate for 30 minutes. \n3. Place the marinated pork ribs on a baking sheet and roast in the preheated oven for 60 minutes, or until the ribs are tender and cooked through. \n4. While the pork ribs are roasting, wash and trim the asparagus spears. Cut the potatoes into small wedges. \n5. In a separate baking dish, arrange the asparagus spears and potato wedges. Drizzle with olive oil and sprinkle with salt and pepper to taste. \n6. After the pork ribs have roasted for 45 minutes, add the baking dish with asparagus and potatoes to the oven. Roast for an additional 15 minutes, or until the vegetables are tender and slightly golden. \n7. Serve the roasted pork ribs with the asparagus and potatoes as a complete meal. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-MBTFyw47i3PcuM1IeHthJajH.png?st=2023-09-04T09%3A40%3A40Z&se=2023-09-04T11%3A40%3A40Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T06%3A15%3A01Z&ske=2023-09-05T06%3A15%3A01Z&sks=b&skv=2021-08-06&sig=jG50sVHEigvkpf4UG5bXcPQbjPRiDBkeBCNUVfoAWDg%3D",
        "cookingTime": 90,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f5b60c2d25959940eebb5f",
        "name": "Cheesy Chicken and Bean Casserole",
        "description": "A delicious and satisfying casserole dish made with beans, cheese, and chicken.",
        "ingredients": [
            "2 cups cooked and shredded chicken",
            "2 cups canned beans (any type)",
            "2 cups shredded cheese (cheddar or any preferred cheese)",
            "Salt and pepper to taste"
        ],
        "instructions": "1. Preheat the oven to 375°F (190°C).\n2. In a large skillet, cook the chicken until no longer pink. Remove from heat and shred it into small pieces.\n3. In a separate saucepan, heat the beans over medium heat until heated through.\n4. In a baking dish, layer half of the shredded chicken at the bottom.\n5. Spread half of the beans over the chicken layer.\n6. Sprinkle half of the shredded cheese over the beans.\n7. Repeat the layers with the remaining chicken, beans, and cheese.\n8. Cover the baking dish with aluminum foil and bake for 30 minutes.\n9. Remove the foil and bake for an additional 10 minutes or until the cheese is melted and bubbly.\n10. Let it cool for a few minutes before serving.\n11. Enjoy your delicious Cheesy Chicken and Bean Casserole!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-9wN0tD8prb32CVX0L0GD896V.png?st=2023-09-04T09%3A48%3A26Z&se=2023-09-04T11%3A48%3A26Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T02%3A12%3A09Z&ske=2023-09-05T02%3A12%3A09Z&sks=b&skv=2021-08-06&sig=e1BdrtSBrK/NsOGHdMCDgk7%2B1siVc/z40Tw9SH4PhkY%3D",
        "cookingTime": 60,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f5b889ee680fa84840ea9f",
        "name": "Grilled Salmon with Asparagus",
        "description": "A delicious and healthy grilled salmon dish served with seasoned asparagus.",
        "ingredients": [
            "Salmon fillets",
            "Asparagus spears",
            "Salt",
            "Pepper",
            "Olive oil",
            "Choice of herbs or spices"
        ],
        "instructions": "1. Preheat the grill to medium-high heat.\n2. Season the salmon fillets with salt, pepper, and your choice of herbs or spices. Let them sit at room temperature for 10 minutes.\n3. Coat the asparagus spears with olive oil, salt, and pepper.\n4. Place the seasoned salmon fillets and asparagus spears on the grill. Cook the salmon for 4-5 minutes per side, or until cooked through. Cook the asparagus for 5-6 minutes, turning occasionally.\n5. Remove the salmon and asparagus from the grill. Serve immediately.",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-HVzvU7fiTPN8oBVmmNwPfgU8.png?st=2023-09-04T09%3A58%3A26Z&se=2023-09-04T11%3A58%3A26Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T06%3A12%3A48Z&ske=2023-09-05T06%3A12%3A48Z&sks=b&skv=2021-08-06&sig=rN8qu3eTFoRN6YWItGg5GdFMJne8zPaAjkGvV%2BLjcp0%3D",
        "cookingTime": 30,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f5eb6cbca7e97359b3bd59",
        "name": "Barbecue Pork Ribs with Beans",
        "description": "Tender and flavorful pork ribs slow-cooked to perfection, served with a hearty helping of beans.",
        "ingredients": [
            "Pork ribs",
            "Salt",
            "Pepper",
            "Barbecue rub",
            "Beans",
            "Onion",
            "Garlic",
            "Chicken broth",
            "Honey",
            "Spices (e.g., paprika, cumin)"
        ],
        "instructions": "1. Preheat your grill or oven to 325°F (165°C).\n2. Season the pork ribs generously with salt, pepper, and your favorite barbecue rub.\n3. Place the ribs on the grill or in the oven. Cook for 1.5-2 hours, or until the meat is tender and starts to pull away from the bones.\n4. While the ribs are cooking, prepare the beans. Drain and rinse a can of your preferred beans.\n5. In a sauté pan, heat a tablespoon of oil over medium heat. Add a chopped onion and minced garlic. Sauté until the onion becomes translucent.\n6. Add the beans, along with a cup of chicken broth, a tablespoon of honey, and a teaspoon of your favorite spices (such as paprika or cumin).\n7. Simmer the bean mixture for about 20 minutes, stirring occasionally, until the beans are tender and the flavors have melded.\n8. Once the ribs are done cooking, remove them from the grill or oven and let them rest for a few minutes.\n9. Serve the ribs with a generous spoonful of beans on the side. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-t7bLKCmlj8F31I6g5tfiiP2P.png?st=2023-09-04T13%3A36%3A09Z&se=2023-09-04T15%3A36%3A09Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T04%3A00%3A21Z&ske=2023-09-05T04%3A00%3A21Z&sks=b&skv=2021-08-06&sig=d6BvKfRbWvysWVSpENt%2BscGbkrqSYSKwB242dYLNNmk%3D",
        "cookingTime": 120,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    },
    {
        "_id": "64f73c4d19a7f5d597d48a12",
        "name": "Bean and Apple Salad",
        "description": "A refreshing and nutritious salad combining the earthiness of beans with the crisp sweetness of apples.",
        "ingredients": [
            "1 can of beans (any variety)",
            "2 medium apples",
            "Fresh greens (optional)"
        ],
        "instructions": "1. Rinse and drain the beans. \n2. Core and dice the apples. \n3. In a large bowl, combine the beans and diced apples. \n4. Toss well to mix. \n5. Serve as a side dish or over a bed of fresh greens. Enjoy!",
        "imageUrl": "https://oaidalleapiprodscus.blob.core.windows.net/private/org-q6PaZOAjDK6fmMoKl3gM4xrq/user-xfgJ3oAr7JvmkAYuV2rWI1qy/img-0UbpiRPLrqb9onuyetSwLISj.png?st=2023-09-05T13%3A29%3A29Z&se=2023-09-05T15%3A29%3A29Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-04T16%3A09%3A47Z&ske=2023-09-05T16%3A09%3A47Z&sks=b&skv=2021-08-06&sig=efaCO8mEaNP5DdZpbamhsLiZRLxKiXk3jd1buS%2Bx2Zg%3D",
        "cookingTime": 20,
        "userOwner": "64f4f20cd0c13df4e119bf27",
        "__v": 0
    }
]
export const state = proxy({
    recipes: JSON.parse(JSON.stringify(defaultData)) || [],
    ingredients: [],
});